<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:240px;"
            />
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="등록일(From)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="등록일(To)"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="query.filterStr"
              placeholder="검색어 입력(설문제목)"
              class="el-def"
              style="width:300px;"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search mr-50"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              결과 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDown()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        :no-wrap="true"
        :items="resultTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        selectable
        sticky-header
        select-mode="single"
        class="position-relative"
        style="max-height: 65vh; font-size: 12px"
      >
        <template #cell(submitDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="10"
        align="center"
      />
    </b-card>

    <div>
      <b-modal
        v-model="cancelModalVisible"
        title="발송 된 기프티콘을 취소하시겠습니까?"
        no-close-on-esc
        no-close-on-backdrop
        @ok.prevent="cancelOrder()"
      >
        <p class="mt-1">
          기프티콘업체: {{ currentOrder.companyName }}
        </p>
        <p>
          보상구분: {{ currentOrder.rewardTypeName }}
        </p>
        <p>
          상품명: {{ currentOrder.goodsName }}
        </p>
        <p>
          휴대폰번호: {{ currentOrder.phoneNumber }}
        </p>
        <p>
          발송일시: {{ currentOrder.sendDateStr }}
        </p>
        <p
          v-if="failureMessage"
          class="text-danger"
        >
          실패메시지: {{ failureMessage }}
        </p>
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="reOrderModalVisible"
        title="기프티콘을 다시 발송 하시겠습니까?"
        no-close-on-esc
        no-close-on-backdrop
        @ok.prevent="reOrder()"
      >
        <p class="mt-1">
          기프티콘업체: {{ currentOrder.companyName }}
          <span
            v-if="currentOrder.company === 2"
            class="text-primary"
          >
            (* 카카오 기프티콘의 경우 발송 시점에 성공 여부를 알 수 없습니다. (발송 접수 성공/실패 여부만 확인 가능)
            따라서 성공으로 표시되더라도 3~4초 뒤에 목록을 다시 조회하시어 "실패"로 변경되지 않았는지 확인해 주세요.)
          </span>
        </p>
        <p>
          보상구분: {{ currentOrder.rewardTypeName }}
        </p>
        <p>
          상품명: {{ currentOrder.goodsName }}
        </p>
        <p>
          휴대폰번호: {{ currentOrder.phoneNumber }}
        </p>
        <p>
          발송실패일시: {{ currentOrder.sendDateStr }}
        </p>
        <p
          v-if="failureMessage"
          class="text-danger"
        >
          실패메시지: {{ failureMessage }}
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, onMounted, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { saveAs } from "file-saver"
import dayjs from 'dayjs'

export default {
  components: {
    vSelect,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
      fetchList()
    })
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const codes = ref({
      clients: [],
    })
    const cancelModalVisible = ref(false)
    const reOrderModalVisible = ref(false)

    // Query Data
    const query = ref({
      startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      company: null,
      rewardType: null,
      isFailure: null,
    })

    const currentOrder = ref({})
    const failureMessage = ref('')

    const giftOrderId = ref(0)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const resultTable = ref([])

    const tableColumns = ref([
      {
        key: 'clientName', label: '제약사명', sortable: false, thStyle: { width: '8%' },
      },
      {
        key: 'liveInfoId', label: 'ID', sortable: false, thStyle: { width: '6%' },
      },
      {
        key: 'title', label: '제목', sortable: false, thStyle: { width: '15%' },
      },
      {
        key: 'hcpName', label: '참여자', sortable: false, thStyle: { width: '6%' },
      },
      {
        key: 'hcpWorkPlace', label: '요양기관', sortable: false, thStyle: { width: '10%' },
      },
      {
        key: 'hcpMedicalDeptName', label: '진료과', sortable: false, thStyle: { width: '9%' },
      },
      {
        key: 'phoneNumber', label: '연락처', sortable: false, thStyle: { width: '8%' },
      },
      {
        key: 'memberGubun', label: '회원여부', sortable: false, thStyle: { width: '6%' },
      },
      {
        key: 'statusName', label: '진행상태', sortable: false, thStyle: { width: '7%' },
      },
      {
        key: 'rewardDesc', label: '보상내역', sortable: false, thStyle: { width: '12%' },
      },
      {
        key: 'submitDate', label: '제출일시', sortable: false, thStyle: { width: '9%' },
      },
    ])

    const fetchCodes = () => {
      axios
        .get('/fa/survey-answer-user/codes')
        .then(rs => {
          // eslint-disable-next-line no-shadow
          const { clients } = rs.data
          codes.value.clients = clients
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const currPage = ref(1)
    const totalRecords = ref(0)
    const fetchList = () => {
      if (!query.value.startDate || !query.value.endDate) {
        pushToast('warning', '조회 기간을 지정하세요.')
        return
      }

      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)
      if (Math.abs(frDate.diff(toDate, 'day')) > 90) {
        pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
        return
      }

      axios
        .post('/fa/survey-answer-user/list', {
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          clientId: query.value.clientId,
          filterStr: query.value.filterStr,
          sort: {
            predicate: sortBy.value,
            reverse: isSortDesc.value,
          },
          pagination: {
            number: currPage.value,
            count: 10,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data

          resultTable.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const refetchData = () => {
      fetchList()
    }

    // watch(query.value, () => {
    //   fetchList()
    // })
    watch([currPage], () => {
      fetchList()
    })

    const excelDown = () => {
      axios.post('/fa/survey-answer-user/list-excel', {
        startDate: query.value.startDate,
        endDate: query.value.endDate,
        clientId: query.value.clientId,
        filterStr: query.value.filterStr,
      }, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])
          const fileName = '설문조사참여자리스트'

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    return {
      sortBy,
      currPage,
      totalRecords,
      isSortDesc,
      tableColumns,
      fetchCodes,
      toast,
      query,
      codes,
      fetchList,
      excelDown,
      resultTable,
      watch,
      refetchData,
      giftOrderId,
      cancelModalVisible,
      reOrderModalVisible,
      currentOrder,
      failureMessage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
